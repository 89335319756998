<ng-template #template>
  <div class="rest-icon"></div>

  <div class="row main-filter-header-rtl">
    <h6 class="modal-title pull-left col-md-4">Filter By</h6>
    <div class="col-md-6 modal-title text-end">
      <a
        (click)="addNewFilterValue()"
        style="cursor: pointer"
        *ngIf="getFilterValues?.controls?.length <= coloumnsData.length"
        ><span
          class="text-success material-icons-outlined filter-btn-icon icon-font-size"
          ><mat-icon>add</mat-icon></span
        ></a
      >

      <a style="cursor: pointer" (click)="resetFilter()">
        <span class="text-end"
          ><i class="material-icons-outlined filter-btn-icon icon-font-size"
            >restart_alt</i
          ></span
        ></a
      >
    </div>
  </div>

  <div class="modal-body filter-rtl p-2">
    <div class="col-md-12 mt-3 filter-container">
      <form [formGroup]="filterForm" class="filter-form">
        <div formArrayName="Filters">
          <div
            *ngFor="let listItem of getFilterValues?.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="row filter-rtl mt-3">
              <div class="col-md-12">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel
                    disabled="{{ !listItem.get('IsActive').value }}"
                  >
                    <mat-expansion-panel-header [collapsedHeight]="'130px'">
                      <div class="display-header">
                        <mat-slide-toggle
                          formControlName="IsActive"
                        ></mat-slide-toggle>
                        <span
                          class="text-success"
                          style="cursor: pointer"
                          (click)="addNewFilterValue()"
                          *ngIf="
                            getFilterValues?.controls?.length <=
                            coloumnsData.length
                          "
                          ><mat-icon>add</mat-icon></span
                        >
                        <span
                          class="text-danger"
                          style="cursor: pointer"
                          (click)="removeFilterValue(i)"
                          ><mat-icon>close</mat-icon></span
                        >
                      </div>
                      <ul class="mt-3">
                        <li>
                          <b> Filter By : </b>
                          {{
                            getFilterValues?.controls[i]?.get('Translate').value
                              | translate
                          }}
                        </li>
                        <li>
                          <b> Condition : </b>
                          {{
                            getFilterValues?.controls[i]?.get('ConditionText')
                              .value
                          }}
                        </li>
                        <li
                          class="
                            {{
                            listItem.get('Condition').value == 'IsEmpty' ||
                            listItem.get('Condition').value == 'IsNotEmpty'
                              ? 'd-none'
                              : 'd-block'
                          }}
                          "
                        >
                          <b>Value :</b>
                          <span>
                            {{
                              listItem.get('DataType').value == 'LOOKUP' &&
                              allowLookup.includes(
                                listItem.get('Condition').value
                              )
                                ? joinValue
                                : listItem.get('DataType').value == 'DATE'
                                ? (getFilterValues?.controls[i]?.get('Value')
                                    .value | date : 'dd/MM/yyyy')
                                : getFilterValues?.controls[i]?.get('Value')
                                    .value
                            }}
                          </span>
                          <span
                            *ngIf="listItem.get('Condition').value == 'Between'"
                          >
                            -
                            {{
                              listItem.get('DataType').value == 'DATE'
                                ? (listItem.get('SecondaryValue').value
                                  | date : 'dd/MM/yyyy')
                                : listItem.get('SecondaryValue').value
                            }}</span
                          >
                        </li>
                      </ul>
                    </mat-expansion-panel-header>
                    <div
                      class="{{
                        !listItem.get('IsActive').value ? 'disabled-card' : ''
                      }} "
                    >
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-3"></div>
                          <div class="col-md-9">
                            <h5 class="card-title text-end"></h5>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12" *ngIf="i > 0">
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <mat-label></mat-label>
                              <mat-select
                                formControlName="FilterClause"
                                class="disabled"
                                (selectionChange)="filterByChange($event, i)"
                              >
                                <mat-option
                                  class="form-select-option"
                                  *ngFor="
                                    let clause of listItem.get(
                                      'FilterClauseArray'
                                    ).value
                                  "
                                  [value]="clause?.id"
                                >
                                  {{ clause?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="col-md-12">
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <mat-label> Filter By</mat-label>
                              <mat-select
                                formControlName="FilterBy"
                                class="disabled"
                                (selectionChange)="filterByChange($event, i)"
                              >
                                <mat-option
                                  class="form-select-option"
                                  *ngFor="
                                    let coloumn of listItem.get('ColoumnsArray')
                                      .value
                                  "
                                  [value]="coloumn?.data"
                                >
                                  {{
                                    coloumn?.translate | translate
                                  }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="col-md-12">
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <mat-label> Condition</mat-label>
                              <mat-select
                                formControlName="Condition"
                                (selectionChange)="conditionChange($event, i)"
                              >
                                <mat-option
                                  class="form-select-option"
                                  *ngFor="
                                    let condition of listItem.get(
                                      'ConditionArray'
                                    ).value
                                  "
                                  [value]="condition?.id"
                                >
                                  {{ condition?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-12">
                            <mat-form-field
                              class="example-full-width form-input-field {{
                                (listItem.get('DataType').value == 'LOOKUP' &&
                                  allowLookup.includes(
                                    listItem.get('Condition').value
                                  )) ||
                                listItem.get('DataType').value == 'DATE' ||
                                listItem.get('Condition').value ==
                                  'IsNotEmpty' ||
                                listItem.get('Condition').value == 'IsEmpty'
                                  ? 'd-none'
                                  : ''
                              }} "
                            >
                              <mat-label>
                                {{
                                  listItem.get('Condition').value == 'Between'
                                    ? 'From'
                                    : 'Value'
                                }}
                              </mat-label>

                              <input
                                type="text"
                                formControlName="Value"
                                matInput
                                (keyup)="onKeyUp($event, i)"
                                class=""
                              />
                            </mat-form-field>
                          </div>
                          <div
                            class="col-md-12 {{
                              listItem.get('DataType').value == 'LOOKUP' &&
                              allowLookup.includes(
                                listItem.get('Condition').value
                              )
                                ? ''
                                : 'd-none'
                            }}"
                          >
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <mat-label>
                                {{
                                  listItem.get('Condition').value == 'Between'
                                    ? 'From'
                                    : 'Value'
                                }}
                              </mat-label>

                              <mat-select
                                formControlName="LookupValue"
                                class=""
                                multiple
                                (selectionChange)="getlookupvalue(i)"
                              >
                                <mat-option
                                  class="form-select-option"
                                  *ngFor="
                                    let condition of listItem.get('LookupArray')
                                      .value
                                  "
                                  [value]="condition?.id"
                                >
                                  {{ condition?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div
                            class="col-md-12"
                            *ngIf="
                              listItem.get('Condition').value == 'Between' &&
                              listItem.get('DataType').value != 'DATE'
                            "
                          >
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <mat-label> To</mat-label>
                              <input
                                type="text"
                                formControlName="SecondaryValue"
                                matInput
                                (keyup)="onKeyUp($event, i)"
                                [min]="
                                  listItem.get('Condition').value ==
                                    'Between' &&
                                  listItem.get('DataType').value != 'DATE'
                                    ? getFilterValues?.controls[i]?.get('Value')
                                        .value
                                    : null
                                "
                              />
                            </mat-form-field>
                            <mat-error
                              *ngIf="
                                getFilterValues?.controls[i]
                                  ?.get('SecondaryValue')
                                  .hasError('min')
                              "
                            >
                              "To value" should be equal or greater than "from value"
                            </mat-error>
                          </div>

                          <div
                            class="col-md-12 {{
                              listItem.get('DataType').value == 'DATE'
                                ? ''
                                : 'd-none'
                            }}"
                          >
                            <mat-form-field
                              appearance="fill"
                              class="example-full-width form-input-field"
                            >
                              <input
                                readonly
                                matInput
                                [matDatepicker]="picker"
                                [placeholder]="
                                  listItem.get('Condition').value ==
                                    'Between' &&
                                  listItem.get('DataType').value == 'DATE'
                                    ? 'From Date'
                                    : 'Choose a date'
                                "
                                (dateChange)="addDate($event, i, true)"
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="picker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field
                              appearance="fill"
                              class="example-full-width form-input-field"
                              *ngIf="
                                listItem.get('Condition').value == 'Between' &&
                                listItem.get('DataType').value == 'DATE'
                              "
                            >
                              <input
                                readonly
                                matInput
                                [matDatepicker]="picker"
                                placeholder="To Date"
                                formControlName="SecondaryValue"
                                (dateChange)="addDate($event, i, false)"
                                [min]="
                                  listItem.get('Condition').value ==
                                    'Between' &&
                                  listItem.get('DataType').value == 'DATE'
                                    ? getFilterValues?.controls[i]?.get('Value')
                                        .value
                                    : null
                                "
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="picker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <div
                              *ngIf="
                                listItem.get('Condition').value == 'IsAnyOf' &&
                                listItem.get('DataType').value == 'DATE'
                              "
                            >
                              <span
                                class="badge col-green"
                                *ngFor="
                                  let date of this.getFilterValues.controls[
                                    i
                                  ]?.get('Dates')?.value;
                                  let idx = index
                                "
                                (click)="removeDate(idx, i)"
                              >
                                {{ date.value | date : 'dd-MM-yyyy' }}
                                <mat-icon>cancel</mat-icon>
                              </span>
                            </div>
                            <mat-chip-list> </mat-chip-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <hr />
  <div class="filter-close-div main-filter-footer-rtl">
    <button
      mat-flat-button
      color="primary"
      class="flat-info-btn btn-text-size"
      (click)="applyFilter()"
    >
      Apply Filter<mat-icon>add</mat-icon></button
    >&nbsp;&nbsp;
    <button
      type="button"
      mat-stroked-button
      color="primary"
      class="btn-border outline-info-btn btn-text-size"
      (click)="modalRef.hide()"
    >
      Close</button
    >&nbsp;&nbsp;
  </div>
</ng-template>

<div class="tab-form-container" id="document-container">
  <form [formGroup]="documentAttachmentForm">
      <div class="row  mt-3" *ngIf="!isReadOnly">
          <div class="col-md-3">
              <h6 class="mb-3" *ngIf="!isEditMode">{{'COMMON_DOCUMENT_MENU.MULTIPLE_ATTACHMENT' | translate }}</h6>
              <h6 class="mb-3" *ngIf="isEditMode"><b>{{'COMMON.EDIT_ATTACHMENT' | translate }}</b></h6>
          </div>
      </div>

      <div class="row" *ngIf="!isReadOnly">
          <div class="col-md-6">
              <mat-form-field class="example-full-width form-input-field">
                  <mat-label> {{'COMMON_DOCUMENT_MENU.DOCUMENT_NAME' | translate}}</mat-label>
                  <input formControlName="AttachmentName" type="text" matInput required>
              </mat-form-field>
          </div>
          <div class="col-md-6" *ngIf="isEditMode">
              <div class="btn-add-flex toggle-container pt-0 pr-2">
                  <mat-label class="text-active">{{'COMMON_DOCUMENT_MENU.DOCUMENT_STATUS' | translate}}</mat-label>
              </div>
              <div class="btn-add-flex toggle-container pt-1">
                  <mat-slide-toggle formControlName="Status"></mat-slide-toggle>
              </div>
          </div>
      </div>

      <div class="row" *ngIf="!isReadOnly">
          <div class="col-md-10">
          <mat-form-field class="example-full-width form-input-field">
              <mat-label>{{ 'COMMON_DOCUMENT_MENU.ATTACHMENT_DESCRIPTION' | translate }}</mat-label>
              <input type="text" matInput formControlName="AttachmentDescription">
          </mat-form-field>
          </div>
      </div>

      <div class="row mt-2" *ngIf="!isReadOnly">
          <div class="col-md-10">
              <div *ngIf="currentUrl == '' || currentUrl == null">
                  <app-file-upload id="doc-upload" [headerName]="'Upload document'" (change)="onDocumentFileSelected($event, 2)"></app-file-upload>
              </div>
              <div *ngIf="currentUrl != '' && currentUrl != null">
                  <div class="text-center mb-2">
                      <a href="{{currentUrl}}" target="_blank" rel="noopener noreferrer">Click Here to View Document</a>
                      <!-- <img src="{{currentUrl}}" alt="Avatar" class="image mx-auto"> -->
                  </div>
                  <div class="delete-btn text-center" *ngIf="currentUrl != '' && currentUrl != null">
                      <button mat-raised-button color="warn" (click)="deleteImg()">Delete Document</button>
                  </div>
              </div>
          </div>
      </div>
      <div class="row  mt-3">
          <div class="col-md-12">
              <div class="row  mt-3" *ngIf="!isReadOnly">
                  <div *ngIf="!isEditMode" class="col-md-12 d-flex justify-content-end mb-3">
                      <button
                      mat-flat-button
                      class="btn-text-size mt-2 ml-5 bg-Info"
                      color="primary"
                      (click)="addAttachment()"
                      [disabled]="!documentAttachmentForm.valid || currentUrl == ''"
                  >
                     {{ 'COMMON.ADD_ATTACHMENT_RECORD' | translate }}
                      <mat-icon>add</mat-icon>
                  </button>
                  </div>
                  <div *ngIf="isEditMode" class="col-md-12 d-flex justify-content-end mb-3 mr-2">
                      <button
                      mat-flat-button
                      class="btn-text-size mt-2 ml-5 bg-status-draft edit-att-btn"
                      color="primary"
                      (click)="clearEdit()"
                      [disabled]="!documentAttachmentForm.valid || currentUrl == ''"
                  >
                     {{ 'COMMON.CANCEL_EDIT_ATTACHMENT' | translate }}
                      <mat-icon>cancel</mat-icon>
                      </button>
                      <button
                      mat-flat-button
                      class="btn-text-size mt-2 ml-5 bg-Info"
                      color="primary"
                      (click)="editAttachment()"
                      [disabled]="!documentAttachmentForm.valid || currentUrl == ''"
                  >
                     {{ 'COMMON.EDIT_ATTACHMENT_RECORD' | translate }}
                      <mat-icon>edit</mat-icon>
                      </button>
                  </div>
              </div>
          <table mat-table [dataSource]="dataSource" class="fleet-table">
              <ng-container matColumnDef="documentName">
                  <th mat-header-cell *matHeaderCellDef>{{ 'COMMON_DOCUMENT_MENU.DOCUMENT_NAME' | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <img *ngIf="element?.fileType.includes('pdf')" src="../../../../assets/images/image-gallery/pdf-image.png" class="doc-icon" alt="User">
                    <img *ngIf="element?.fileType.includes('png')" src="../../../../assets/images/image-gallery/png-image.png" class="doc-icon" alt="User">
                    <img *ngIf="element?.fileType.includes('jpeg')" src="../../../../assets/images/image-gallery/jpg-image.png" class="doc-icon" alt="User">
                    <!-- <img *ngIf="attachment.documentUrl == ''" src="../../../../../assets/images/image-gallery/pdf-image.png" class="doc-icon" alt="User"> -->
                    {{element?.documentName}} </td>
              </ng-container>
              <ng-container matColumnDef="attachmentDescription">
                  <th mat-header-cell *matHeaderCellDef>{{ 'COMMON_DOCUMENT_MENU.ATTACHMENT_DESCRIPTION' | translate }}</th>
                  <td mat-cell *matCellDef="let element"> {{element?.description}} </td>
              </ng-container>
              <ng-container matColumnDef="link">
              <th mat-header-cell *matHeaderCellDef class="pl-2">{{ 'COMMON_DOCUMENT_MENU.LINK' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                  <a href="{{element?.documentUrl}}" target="_blank" rel="noopener noreferrer"><span class="material-icons-outlined">link</span></a>
                  <!-- <a href="{{element?.documentUrl}}" target="_blank" rel="noopener noreferrer">{{element?.documentUrl}}</a> -->
              </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>{{ 'COMMON_DOCUMENT_MENU.DOCUMENT_STATUS' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <span class="badge bg-green" *ngIf="element.isActive">{{'COMMON.ACTIVE' | translate}}</span>
                    <span class="badge bg-grey" *ngIf="!element.isActive">{{'COMMON.INACTIVE' | translate}}</span>
                    <!-- <mat-slide-toggle [checked]="element.isActive" [disabled]="isReadOnly"></mat-slide-toggle> -->
                </td>
            </ng-container>
              <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> <span *ngIf="!isReadOnly">{{ 'COMMON_DOCUMENT_MENU.ACTION' | translate }}</span></th>
              <td mat-cell *matCellDef="let element; let i = index">
                  <a class="col-grey" (click)="handleClickEditRecord(i)" *ngIf="!isReadOnly"><span class="material-icons-outlined" >edit</span></a>
                  <a class="col-red" (click)="removeAttachment(i)" *ngIf="!isReadOnly"><span class="material-icons-outlined">delete</span></a>
              </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
          </table>
          <div>

          </div>
          <mat-paginator
              class="customPaginator"
              [pageSizeOptions]="[5, 10, 20]"
              [pageSize]="20"
              showFirstLastButtons
              aria-label="Select page of periodic elements">
          </mat-paginator>
          </div>
      </div>
  </form>
</div>

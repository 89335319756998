import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BaseDataService {
  baseUrl: string;
  private timeZone: string;
  private lang: string = 'en';
  private companyId: string = '';

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this.timeZone = this.getTimeZone();
    this.lang = localStorage.getItem('lang') ?? 'en';

    this.getCompanyId();
  }

  getCompanyId() {
    var company = localStorage.getItem('currentCompany');
    this.companyId = company ? JSON.parse(company)?.id : '';
  }

  getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  private getToken() {
    return localStorage.getItem('token');
  }

  private constructUrl(path: string) {
    return this.baseUrl + path;
  }

  makeGetCall(path: string): Observable<any> {
    this.getCompanyId();

    let headers = new HttpHeaders({
      Authorization: 'bearer ' + this.getToken(),
      lang: this.lang,
      companyId: this.companyId ?? '',
      timeZone: this.timeZone,
    });
    return this.http.get(this.constructUrl(path), { headers: headers });
  }

  makePostCall(path: string, body?: any): Observable<any> {
    this.getCompanyId();

    let bodyJson = JSON.stringify(body);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: 'bearer ' + this.getToken(),
      lang: this.lang,
      companyId: this.companyId ?? '',
      timeZone: this.timeZone,
    });
    return this.http.post(this.constructUrl(path), bodyJson, {
      headers: headers,
    });
  }

  makePostCallImageUpload(path: string, form: any): Observable<any> {
    return this.http.post(this.constructUrl(path), form);
  }

  makePostCallExcelUpload(path: string, form: any): Observable<any> {
    let headers = new HttpHeaders({
      Authorization: 'bearer ' + this.getToken(),
      lang: this.lang,
      companyId: this.companyId ?? '',
      timeZone: this.timeZone,
    });

    return this.http.post(this.constructUrl(path), form, { headers: headers });
  }

  makePostDownloadCall(path: string, body: any): Observable<any> {
    this.getCompanyId();

    let bodyJson = JSON.stringify(body);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: 'bearer ' + this.getToken(),
      lang: this.lang,
      companyId: this.companyId ?? '',
      timeZone: this.timeZone,
    });
    return this.http.post(this.constructUrl(path), bodyJson, {
      headers: headers,
      responseType: 'blob',
    });
  }

  makePutCall(path: string, body: any): Observable<any> {
    this.getCompanyId();

    let bodyJson = JSON.stringify(body);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: 'bearer ' + this.getToken(),
      lang: this.lang,
      companyId: this.companyId ?? '',
      timeZone: this.timeZone,
    });
    return this.http.put(this.constructUrl(path), bodyJson, {
      headers: headers,
    });
  }

  makeDeleteCall(path: string): Observable<any> {
    this.getCompanyId();

    let headers = new HttpHeaders({
      Authorization: 'bearer ' + this.getToken(),
      lang: this.lang,
      companyId: this.companyId ?? '',
      timeZone: this.timeZone,
    });
    return this.http.delete(this.constructUrl(path), { headers: headers });
  }

  makeBulkDeleteCall(path: string, body: any): Observable<any> {
    this.getCompanyId();

    let bodyJson = JSON.stringify(body);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: 'bearer ' + this.getToken(),
      lang: this.lang,
      companyId: this.companyId ?? '',
      timeZone: this.timeZone,
    });
    return this.http.post(this.constructUrl(path), bodyJson, {
      headers: headers,
    });
  }

  makeGetDownloadCall(path: string): Observable<any> {
    this.getCompanyId();

    let headers = new HttpHeaders({
      Authorization: 'bearer ' + this.getToken(),
      lang: this.lang,
      companyId: this.companyId ?? '',
      timeZone: this.timeZone,
    });
    return this.http.get(this.constructUrl(path), {
      headers: headers,
      responseType: 'blob',
    });
  }
}

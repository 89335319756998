import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { LanguageService } from 'src/app/core/service/language.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import * as moment from 'moment';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { FileUploadData } from 'src/app/shared/models/file-upload.model';
import { FileUploadService } from "../../services/file-upload.service";
import { DocumentUpload } from '../../models/document-upload.model';
import { DocumentUploadSharedService } from '../../services/document-upload-shared.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-common-document-upload',
  templateUrl: './common-document-upload.component.html',
  styleUrls: ['./common-document-upload.component.scss']
})
export class CommonDocumentUploadComponent {
  @Input() categoryList = [];
  @Input() moduleType = "";
  @Input() attachmentId = ""
  @Input() isReadOnly = false
  documentAttachmentForm: FormGroup;
  currentUrl: string = "";
  fileType = "";
  attachmentList: DocumentUpload[] = []
  attachmentObj: DocumentUpload
  isFileUploading : boolean = false;
  categorySearch: string =''
  displayedColumns: string[] = ['documentName','attachmentDescription','link','status','action'];
  dataSource = new MatTableDataSource<DocumentUpload>(this.attachmentList);
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  addOnBlur = true;
  documentAttachmentDataSubject: Subscription
  @ViewChild(MatPaginator) paginator: MatPaginator;
  editIndex: number;

  isEditMode: boolean = false;

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private helperService: HelpersService,
    private router: Router,
    private fb: FormBuilder,
    private paginatorIntl: MatPaginatorIntl,
    private dateAdapter: DateAdapter<Date>,
    private fileUploadService: FileUploadService,
    private documentUploadSharedService: DocumentUploadSharedService
  ) {
    this.documentAttachmentDataSubject = documentUploadSharedService
    .getsubjectDocumentAttachmentList()
    .pipe(takeUntil(this.destroy))
    .subscribe((list: DocumentUpload[]) => {
      if (list != null) {
        this.attachmentList = list;
        this.dataSource = new MatTableDataSource<DocumentUpload>(this.attachmentList);
        this.dataSource.paginator = this.paginator;
      }
    });
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.languageService.getLanguageChangedSubject().pipe(takeUntil(this.destroy))
    .subscribe((flag: string) => {
      if (flag!=null) {
        this.setTranslations();
      }
    });
  }
  ngOnInit(): void {
    this.createAddVehicleForm();
    this.setTranslations();
  }

  createAddVehicleForm() {
    this.documentAttachmentForm = this.fb.group({
      AttachmentDescription: [''],
      AttachmentName: ['', Validators.required],
      Status: true
    })
  }

  setTranslations() {
    this.translateService
      .get([
        'COMMON.ITEMS_PER_PAGE'
      ])
      .subscribe((translations) => {
        this.paginatorIntl.itemsPerPageLabel = translations['COMMON.ITEMS_PER_PAGE'];
      });
  }

  checkFileType(fileType: any) {
    if(fileType.includes('png') || fileType.includes('jpeg') || fileType.includes('pdf') || fileType.includes('jpg')){
      return false
    } else {
      return true
    }
  }

  checkFileSize(fileSize: any) {
    if(fileSize < 2097152) { //2097152 byte = 2MB
      return false
    } else {
      return true
    }
  }

  onDocumentFileSelected(event: any , inputNo: number){
    // if (inputNo === 2) {
      var blob=event.target.files[0];
      this.fileType = blob?.type;

      if(this.checkFileType(this.fileType)) {
        this.helperService.showErrorToast('File type should be png, jpg or pdf')

      } else if(this.checkFileSize(blob?.size)) {
        this.helperService.showErrorToast('Document size should be less than 2MB')

      } else {

        let formData: FormData = new FormData();
        formData.append("files", blob, blob.name);
        this.fileUploadService
          .uploadFile(formData)
          .pipe(takeUntil(this.destroy))
          .subscribe((res:FileUploadData) => {

            this.currentUrl = res.imageUrl;
            this.isFileUploading = false;
          });

      }

  }

  deleteImg() {
    this.currentUrl = "";
    this.isFileUploading = false;
  }

  addAttachment() {
    this.attachmentObj = {
      documentName: this.documentAttachmentForm.get('AttachmentName')?.value,
      documentUrl: this.currentUrl,
      description: this.documentAttachmentForm.get('AttachmentDescription')?.value,
      fileType: this.fileType,
      // module: "LEAD",
      module: this.moduleType,
      isActive: true
      // status: this.documentAttachmentForm.get('Status').value
    }
    // if (this.attachmentId) {
    //   this.attachmentObj.id = this.attachmentId
    // }
    this.attachmentList.push(this.attachmentObj);
    this.documentUploadSharedService.setsubjectDocumentAttachmentList(this.attachmentList)
    this.helperService.showSuccessToast("Attachment Record added SuccessFully");
    this.dataSource = new MatTableDataSource<DocumentUpload>(this.attachmentList);
    this.documentAttachmentForm.get('AttachmentName').setValue('')
    // this.documentAttachmentForm.get('AttachmentCategory').setValue('')
    this.documentAttachmentForm.get('AttachmentDescription').setValue('')
    this.documentAttachmentForm.get('Status').setValue(true);
    this.currentUrl = "";
    this.fileType = "";
  }

  editAttachment() {
    let attachmentListCopy = this.attachmentList;
    attachmentListCopy[this.editIndex].documentName = this.documentAttachmentForm.get('AttachmentName')?.value;
    attachmentListCopy[this.editIndex].description = this.documentAttachmentForm.get('AttachmentDescription')?.value;
    attachmentListCopy[this.editIndex].isActive = this.documentAttachmentForm.get('Status')?.value;
    attachmentListCopy[this.editIndex].documentUrl = this.currentUrl;
    this.attachmentList = attachmentListCopy;
    this.documentUploadSharedService.setsubjectDocumentAttachmentList(this.attachmentList)
    this.helperService.showSuccessToast("Attachment Record Updated SuccessFully");
    this.dataSource = new MatTableDataSource<DocumentUpload>(this.attachmentList);
    this.clearEdit();
  }

  handleClickEditRecord(indexNo: number) {
    this.editIndex = indexNo;
    this.isEditMode = true;
    // let element = document.getElementById("scroll-div");
    // element.scrollTop = 0;
    this.documentAttachmentForm = this.fb.group({
      AttachmentDescription: [this.attachmentList[indexNo]?.description],
      AttachmentName: [this.attachmentList[indexNo]?.documentName],
      Status: [this.attachmentList[indexNo]?.isActive]
    })
    this.currentUrl = this.attachmentList[indexNo]?.documentUrl;
  }

  removeAttachment(index: number) {
    Swal.fire({
      title: this.translateService.instant('COMMON.DELETE_ATTACHMENT_RECORD'),
      text: this.translateService.instant('COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_THIS_ACTION_CANNOT_BE_UNDONE'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#e0272a',
      cancelButtonColor: 'transparent',
      confirmButtonText: this.translateService.instant('COMMON.DELETE'),
      cancelButtonText: this.translateService.instant('COMMON.CANCEL'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.remove(index)

      }
    })
  }

  remove(index: number) {
    this.attachmentList.splice(index, 1);
    this.helperService.showSuccessToast("Attachment Record Removed SuccessFully");
    this.dataSource = new MatTableDataSource<DocumentUpload>(this.attachmentList);
  }

  clearEdit() {
    this.isEditMode = false;
    // let element = document.getElementById("scroll-div");
    // element.scrollTop = 0;
    this.documentAttachmentForm.reset();
    this.documentAttachmentForm.get('Status').setValue(true);
    this.currentUrl = '';
  }

  ngOnDestroy(){
    this.attachmentList = [];
    this.documentUploadSharedService.setsubjectDocumentAttachmentList(null)
  }

}

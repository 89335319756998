import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, ViewChild } from '@angular/core';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { LookUpElement } from '../../models/common-data.model';
import { HelpersService } from '../../services/helpers.service';
import { LookupsService } from '../../services/lookups.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivityCall } from 'src/app/home/calls/models/calls.model';
import { CallsService } from 'src/app/home/calls/services/calls.service';
import { CommonSharedService } from '../../services/common-shared.service';

@Component({
  selector: 'app-common-call-modal',
  templateUrl: './common-call-modal.component.html',
  styleUrls: ['./common-call-modal.component.scss'],
})
export class CommonCallModalComponent {
  router: any;
  callId: string;
  isEdit: boolean;
  userSearch: string;
  callForm: FormGroup;
  isSchedule: boolean;
  modalRef: BsModalRef;
  callReq: ActivityCall;
  @Input() module: string;
  selectedCallType: string;
  callDetails: ActivityCall;
  isLoading: boolean = false;
  isCallMissed: boolean = false;
  usersList: LookUpElement[] = [];
  callAddDataSubject: Subscription;
  @Input() callToId?: string = null;
  callTypeList: LookUpElement[] = [];
  callStatusList: LookUpElement[] = [];
  callResultList: LookUpElement[] = [];
  @Input() relatedToId?: string = null;
  callPurposeList: LookUpElement[] = [];
  reminderTypeList: LookUpElement[] = [];
  @Input() callToStatusId?: string = null;
  @Input() relatedToStatusId?: string = null;
  @ViewChild('template', { static: true }) template: any;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private callsService: CallsService,
    private modalService: BsModalService,
    private lookupsService: LookupsService,
    private helpersService: HelpersService,
    private translateService: TranslateService,
    private commonSharedService: CommonSharedService
  ) {
    this.callAddDataSubject = commonSharedService
      .getsubjectOpenMeetingCallModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((callObj: any) => {
        if (callObj) {
          if (callObj?.type == 'Open') {
            this.isEdit = false;
            this.isSchedule = callObj?.isSchedule;
          } else {
            this.isEdit = true;
            this.callId = callObj?.id;
            this.isSchedule = callObj?.isSchedule;
            this.getLogCallByCallId();
          }
          this.showModal();

          if (this.isSchedule && !this.isEdit) {
            this.callForm
              .get('CallTypeId')
              .setValue(
                this.callTypeList?.filter((x) => x.name == 'Outbound')[0]?.id
              );
            this.selectedCallType = 'Outbound';
            this.callForm
              .get('OutgoingCallStatusId')
              .setValue(
                this.callStatusList?.filter((x) => x.name == 'Scheduled')[0]?.id
              );
          }

          if (!this.isSchedule && !this.isEdit) {
            this.callForm
              .get('CallTypeId')
              .setValue(
                this.callTypeList?.filter((x) => x.name == 'Outbound')[0]?.id
              );
            this.selectedCallType = 'Outbound';
            this.callForm
              .get('OutgoingCallStatusId')
              .setValue(
                this.callStatusList?.filter((x) => x.name == 'Completed')[0]?.id
              );
            this.callForm.get('OutgoingCallStatusId').disable();
          }

          this.setDefaultSubject();
        }
      });
  }

  ngOnDestroy(): void {
    this.callForm?.reset();
    this.modalRef?.hide();
    this.destroy.next(null);
    this.commonSharedService.setsubjectOpenMeetingCallModal(null);
    this.commonSharedService.setSubjectLeadOpenActivityCallListChanged(null);
    this.commonSharedService.setSubjectDealOpenActivityCallListChanged(null);
    this.commonSharedService.setSubjectContactOpenActivityCallListChanged(null);
    this.commonSharedService.setSubjectAccountOpenActivityCallListChanged(null);
    this.commonSharedService.setSubjectCampaignOpenActivityCallListChanged(
      null
    );
  }

  ngOnInit(): void {
    this.createAddCallForm();
    this.getLookupList();
  }

  getLookupList() {
    this.getCallStatusList();
    this.getCallTypeList();
    this.getCallPurposeList();
    this.getCallResultList();
    this.getUserInformationList();
    this.getReminderTypeLookupList();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-xl',
    });
  }

  createAddCallForm() {
    this.callForm = this.fb.group({
      CallTypeId: [null],
      OutgoingCallStatusId: [null],
      ToDate: [''],
      ToTime: ['12:00 AM'],
      CallOwnerId: [null],
      Subject: [''],
      CallPurposeId: [null],
      CallAgenda: [''],
      CallResultId: [null],
      Description: [''],
      Minutes: [0],
      IsReminder: [false],
      ReminderOn: [''],
      ReminderAt: [''],
      ReminderType: [null],
    });
  }

  getLogCallByCallId() {
    this.callsService
      .getCalls(this.callId)
      .pipe(takeUntil(this.destroy))
      .subscribe((logCall: ActivityCall) => {
        this.callDetails = logCall;
        this.mapFormData();
      });
  }

  mapFormData() {
    this.callForm = this.fb.group({
      CallTypeId: [this.callDetails.callTypeId],
      OutgoingCallStatusId: [this.callDetails.outgoingCallStatusId],
      ToDate: [
        this.helpersService.getDateFromDbFormatted(this.callDetails.startTime),
      ],
      ToTime: [this.helpersService.getTimeFromDb(this.callDetails.startTime)],
      CallOwnerId: [this.callDetails.callOwnerId],
      Subject: [this.callDetails.subject],
      CallPurposeId: [this.callDetails.callPurposeId],
      CallAgenda: [this.callDetails.callAgenda],
      CallResultId: [this.callDetails.callResultId],
      Description: [this.callDetails.description],
      Minutes: [this.callDetails.duration],
      IsReminder: [this.callDetails.isReminder],
      ReminderOn: [
        this.callDetails?.reminder
          ? this.helpersService.getDateFromDbFormatted(
              this.callDetails?.reminder?.dateTime
            )
          : '',
      ],
      ReminderAt: [
        this.callDetails?.reminder
          ? this.helpersService.getTimeFromDb(
              this.callDetails?.reminder?.dateTime
            )
          : '',
      ],
      ReminderType: [this.callDetails?.reminder?.reminderAlertId],
    });

    this.selectedCallType = this.callTypeList?.filter(
      (x) => x.id == this.callDetails.callTypeId
    )[0]?.name;
  }

  createOrEditCall(fromData: any, approvalState: string) {
    if (this.isEdit) {
      this.editCallLog(fromData, approvalState);
    } else {
      this.createCall(fromData, approvalState);
    }
  }

  createCall(fromData: any, approvalState: string) {
    this.setCallReqBody(fromData, approvalState);
    this.saveCallInDb();
  }

  setCallReqBody(formData: any, status: string) {
    var Date = moment(formData.ToDate).format('DD-MM-YYYY');
    var from = moment(
      Date + ' ' + formData.ToTime,
      'DD-MM-YYYY hh:mm A'
    ).toISOString();

    var reminderOnDate = moment(formData.ReminderOn).format('DD-MM-YYYY');
    var reminderDateTime = moment(
      reminderOnDate + ' ' + formData.ReminderAt,
      'DD-MM-YYYY hh:mm A'
    ).toDate();

    this.callReq = {
      subject: formData.Subject,
      callTypeId: formData.CallTypeId,
      startTime: from,
      callToStatusId: this.callToStatusId,
      callToId: this.callToId,
      relatedToStatusId: this.relatedToStatusId,
      relatedToId: this.relatedToId,
      duration: formData.Minutes ?? 0,
      outgoingCallStatusId: formData.OutgoingCallStatusId,
      callPurposeId: formData.CallPurposeId,
      callResultId: formData.CallResultId ?? null,
      callAgenda: formData.CallAgenda ?? '',
      description: formData.Description ?? '',
      approvalState: status,
      callOwnerId: formData.CallOwnerId ?? null,
      isScheduled: this.isSchedule,
      isReminder: formData.IsReminder ?? false,
      reminder: formData.ReminderType
        ? {
            dateTime: this.helpersService.setDateForDb(reminderDateTime),
            reminderAlertId: formData.ReminderType,
          }
        : null,
    };
  }

  saveCallInDb() {
    this.isLoading = true;
    this.callsService.createCall(this.callReq).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res) {
          this.cancelForm();
          this.helpersService.showSuccessToast('Created Successfully');
          this.setOpenActivityTableChanged();
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  editCallLog(fromData: any, approvalState: string) {
    this.setUpdateCallLogReqBody(fromData, approvalState);
    this.saveUpdateCallInDb();
  }

  setUpdateCallLogReqBody(formData: any, status: string) {
    var Date = moment(formData.ToDate).format('DD-MM-YYYY');
    var from = moment(
      Date + ' ' + formData.ToTime,
      'DD-MM-YYYY hh:mm A'
    ).toISOString();

    var reminderOnDate = moment(formData.ReminderOn).format('DD-MM-YYYY');
    var reminderDateTime = moment(
      reminderOnDate + ' ' + formData.ReminderAt,
      'DD-MM-YYYY hh:mm A'
    ).toDate();

    this.callReq = {
      id: this.callId,
      subject: formData.Subject,
      callTypeId: formData.CallTypeId,
      startTime: from,
      callToStatusId: this.callDetails.callToStatusId,
      callToId: this.callToId,
      relatedToId: this.callDetails.relatedToId,
      relatedToStatusId: this.callDetails.relatedToStatusId,
      duration: formData.Minutes ?? 0,
      outgoingCallStatusId: formData.OutgoingCallStatusId,
      callPurposeId: formData.CallPurposeId,
      callResultId: formData.CallResultId,
      callAgenda: formData.CallAgenda ?? '',
      description: formData.Description ?? '',
      approvalState: status,
      callOwnerId: formData.CallOwnerId,
      isScheduled: this.isSchedule,
      isReminder: formData.IsReminder ?? false,
      reminder: formData.ReminderType
        ? {
            dateTime: this.helpersService.setDateForDb(reminderDateTime),
            reminderAlertId: formData.ReminderType,
          }
        : null,
    };
  }

  saveUpdateCallInDb() {
    this.isLoading = true;
    this.callsService.editCall(this.callReq).subscribe(
      (res: any) => {
        if (res) {
          this.isLoading = false;
          this.cancelForm();
          this.helpersService.showSuccessToast('Updated Successfully');
          this.setOpenActivityTableChanged();
        }
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  getCallTypeList() {
    this.lookupsService
      .getCallMasterLookup('CallType')
      .subscribe((callTypeList: LookUpElement[]) => {
        this.callTypeList = callTypeList;
        if (this.isSchedule && !this.isEdit) {
          this.callForm
            ?.get('CallTypeId')
            .setValue(
              this.callTypeList?.filter((x) => x.name == 'Outbound')[0]?.id
            );
          this.selectedCallType = 'Outbound';
        }

        if (!this.isSchedule && !this.isEdit) {
          this.callForm
            ?.get('CallTypeId')
            .setValue(
              this.callTypeList?.filter((x) => x.name == 'Outbound')[0]?.id
            );
        }
      });
  }

  getCallPurposeList() {
    this.lookupsService
      .getCallMasterLookup('callPurpose')
      .subscribe((callPurposeList: LookUpElement[]) => {
        this.callPurposeList = callPurposeList;
      });
  }

  getCallResultList() {
    this.lookupsService
      .getCallMasterLookup('callResult')
      .subscribe((callResultList: LookUpElement[]) => {
        this.callResultList = callResultList;
      });
  }

  getCallStatusList() {
    this.lookupsService
      .getCallMasterLookup('CallStatus')
      .subscribe((callStatusList: LookUpElement[]) => {
        this.callStatusList = callStatusList;

        if (this.isSchedule && !this.isEdit) {
          this.selectedCallType = 'Outbound';
          this.callForm
            ?.get('OutgoingCallStatusId')
            .setValue(
              this.callStatusList?.filter((x) => x.name == 'Scheduled')[0]?.id
            );
        }
        if (!this.isSchedule && !this.isEdit) {
          this.callForm
            ?.get('OutgoingCallStatusId')
            .setValue(
              this.callStatusList?.filter((x) => x.name == 'Completed')[0]?.id
            );
        }
      });
  }

  getUserInformationList() {
    this.lookupsService
      .getUserInformationLookUp()
      .pipe(takeUntil(this.destroy))
      .subscribe((usersList: LookUpElement[]) => {
        this.usersList = usersList;
      });
  }

  getReminderTypeLookupList() {
    this.lookupsService
      .getReminderRepeaMasterLookup('ReminderAlert')
      .subscribe((reminderTypeList: LookUpElement[]) => {
        this.reminderTypeList = reminderTypeList;
      });
  }

  hancleSelectCallType(event: any) {
    this.callForm.get('OutgoingCallStatusId').setValue(null);
    this.callForm.get('OutgoingCallStatusId').enable();

    this.selectedCallType = this.callTypeList.filter(
      (x) => x.id == event.value
    )[0]?.name;
    if (this.selectedCallType == 'Outbound') {
      this.callForm
        .get('OutgoingCallStatusId')
        .setValue(
          this.callStatusList?.filter((x) => x.name == 'Completed')[0]?.id
        );
      this.callForm.get('OutgoingCallStatusId').disable();
    }

    if (this.selectedCallType == 'Missed') {
      this.isCallMissed = true;
      this.callForm.get('Minutes').setValue(0);
    } else {
      this.isCallMissed = false;
    }
    this.setSubject();
  }

  setSubject() {
    if (!this.isEdit) {
      if (this.selectedCallType == 'Outbound') {
        this.callForm.get('Subject').setValue('Outgoing call to');
      } else if (this.selectedCallType == 'Highest') {
        this.callForm.get('Subject').setValue('Incoming call from');
      } else if (this.selectedCallType == 'Missed') {
        this.callForm.get('Subject').setValue('Missed call from');
      }
    }
  }

  getCurrentDate() {
    return new Date();
  }

  cancelForm() {
    this.callForm.reset();
    this.callForm.get('ToTime').setValue('12:00 AM');
    this.modalRef?.hide();
    this.commonSharedService.setsubjectOpenMeetingCallModal(null);
  }

  backClick() {
    this.modalRef?.hide();
    this.callForm.reset();
  }

  setDefaultSubject() {
    if (!this.isEdit) {
      if (this.isSchedule) {
        this.callForm.get('Subject').setValue('Call scheduled with');
      } else {
        this.callForm.get('Subject').setValue('Outgoing call to');
      }
    }
  }

  setOpenActivityTableChanged() {
    if (this.module == 'Lead') {
      this.commonSharedService.setSubjectLeadOpenActivityCallListChanged(true);
    } else if (this.module == 'Contact') {
      this.commonSharedService.setSubjectContactOpenActivityCallListChanged(
        true
      );
    } else if (this.module == 'Account') {
      this.commonSharedService.setSubjectAccountOpenActivityCallListChanged(
        true
      );
    } else if (this.module == 'Deal') {
      this.commonSharedService.setSubjectDealOpenActivityCallListChanged(true);
    } else if (this.module == 'Campaign') {
      this.commonSharedService.setSubjectCampaignOpenActivityCallListChanged(
        true
      );
    }
  }
}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import {
  ContactPaginated,
  ContactGroupedList,
} from '../models/contact-list.model';

@Injectable({
  providedIn: 'root',
})
export class ContactListService {
  private apiContactUrl = 'api/Contact';

  constructor(public baseDataService: BaseDataService) {}

  getAllContacts(
    baseFilterVm: BaseFilterVm
  ): Observable<ContactPaginated | ContactGroupedList[]> {
    return this.baseDataService.makePostCall(
      `${this.apiContactUrl}/getAllContacts`,
      baseFilterVm
    );
  }

  deleteContact(contactId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(
      `${this.apiContactUrl}/${contactId}`
    );
  }

  bulkContactsDelete(ids: string[]): Observable<any> {
    return this.baseDataService.makeBulkDeleteCall(
      `${this.apiContactUrl}/deleteRangeContactsAsync`,
      ids
    );
  }

  contactExcelExport(filter: BaseFilterVm): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiContactUrl}/contactsExcelExport`,
      filter
    );
  }

  contactTemplateDownload(isTemplateEmpty: boolean): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiContactUrl}/templateDownload?isTemplateEmpty=${isTemplateEmpty}`,
      ''
    );
  }

  contactUploadExcel(form: any, importStatus: number) {
    return this.baseDataService.makePostCallExcelUpload(
      `${this.apiContactUrl}/excelUpload?importStatus=${importStatus}`,
      form
    );
  }
  
}
